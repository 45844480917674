const Styles = {
  mainHeadText:
    "text-[#272727] lg:text-[80px] sm:text-[60px] xs:text-[40px] text-[40px] lg:leading-[98px] mt-2",
  mainSubText:
    "text-[#272727] font-medium lg:text[30px] sm:text-[26px] xs:text-[20px] text-[16px] lg:leading-[40px]",
  sectionHeadText:
    "text-[#272727] pl-8 md:text-[60px] sm:text-[50px] xs:text-[40px] text-[30px]",
  sectionSubText:
    "text-[#272727] sm:text-[18px] text-[14px] uppercase tracking-wider",
  sectionText: "text-[#619B8A] mt-4 pl-8 text-[17px] leading-[30px] max-w-3xl",
};

export { Styles };
