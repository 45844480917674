import React from "react";
import Resume from "../assets/Resume.pdf";
import { Styles } from "../utils/Style";
import { TiArrowDownOutline } from "react-icons/ti";
import Profile from "../assets/muffin.png";
import "../App.css"

function About() {
  return (
    <div className="flex flex-wrap justify-center items-center mt-10 p-10" id="Home">
      <div className="flex-1 py-4 text-center">
        <span className={Styles.mainHeadText}>
          Hello, my name is <span className="text-[#619B8A]">Nate</span>
          <br />
          <span className={Styles.mainSubText}>
            I'm a full-stack developer
          </span>
        </span>
        <br />
        <div className="inline-block"> 
          <a
            href={Resume}
            download="Nathan-Carty-Resume"
            target="_blank" 
            rel="noreferrer"
          >
            <div className="p-4 mt-2 w-[8rem] rounded-xl text-[#619B8A] flex justify-center items-center border-2 border-[#619B8A] hover:border-[#805D93] shadow-lg hover:shadow-[#E072A4]">
              <TiArrowDownOutline className="text-lg mr-2 mt-1 text-[#619B8A]"/>
              Resume
            </div>
          </a>
        </div>
      </div>
      <div className="flex flex-wrap">
        <img src={Profile} alt="profile" className="element object-contain p-4"></img>
      </div>
    </div>
  );
}

export default About;
