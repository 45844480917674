import React, { useState } from 'react';
import Logo from "../assets/logo512.png";
import { AiOutlineMenu } from "react-icons/ai";

const NavBar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    }

    return (
        <div>
            <nav className={`fixed w-full z-20 top-0 ${isOpen ? 'bg-[#F5F5F5] bg-opacity-80' : 'bg-transparent'}`}>
                <div className='max-w-screen-xl flex items-center justify-between mx-auto p-2'>
                    <img src={Logo} alt='logo' className='h-16 ml-4'></img>

                    <div className='md:hidden'>
                        <button onClick={toggle} className='inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-[#272727] rounded-lg focus:ring-2 focus:ring-inherit'>
                            <AiOutlineMenu className='text-[#619B8A] text-lg'></AiOutlineMenu>
                        </button>
                    </div>

                    <div className={`absolute md:relative md:flex md:items-center md:justify-end w-full md:w-auto ${isOpen ? 'bg-[#F5F5F5] bg-opacity-80 top-16' : 'bg-transparent'} ${isOpen ? 'block' : 'hidden'} md:block`}>
                        <ul className='flex flex-col md:flex-row items-center md:space-x-8 p-4 md:p-0 text-xl cursor-pointer'>
                            <li>
                                <div className='block py-2 pl-3 pr-4 rounded md:p-0 hover:text-[#E072A4] text-[#619B8A]'>Home</div>
                            </li>
                            <li>
                                <div className='block py-2 pl-3 pr-4 rounded md:p-0 hover:text-[#E072A4] text-[#619B8A]'>About</div>
                            </li>
                            <li>
                                <div className='block py-2 pl-3 pr-4 rounded md:p-0 hover:text-[#E072A4] text-[#619B8A]'>Projects</div>
                            </li>
                            <li>
                                <div className='block py-2 pl-3 pr-4 rounded md:p-0 hover:text-[#E072A4] text-[#619B8A]'>Contact</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default NavBar;
